import React from 'react'
import { HashLink } from 'react-router-hash-link';

import polanes from '../../assests/polanes.png'

import './Representation.scss'

const Representation = () => {
  return (
    <div className = "representation-root" >

        <h2 className = "representation-title"> Zastupamo </h2>

        <div className = "content-div">
            <img className = "polanes-logo" src = { polanes } alt = "Polanes" />

          <HashLink smooth to = "/zastupnistva#top">
              <button type="button" className="button-secondary">Vidi više</button>
          </HashLink>

        </div>

    </div>
  )
}

export default Representation