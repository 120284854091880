import React from 'react'
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import logo from '../../assests/logo.svg'
import phoneIcon from '../../assests/phoneIcon2.svg'
import emailIcon from '../../assests/emailIcon2.svg'
import addressIcon from '../../assests/address-location-icon2.svg'
import youtubeIcon from '../../assests/youtube-icon.svg'

import './Footer.scss';

const Footer = () => {
  return (
    <div className = "footer-root">

    <div className = "footer-info-div">
        <div className="contact-div">
            <h2 className="footer-info-title"> Kontakt </h2>

            <span>
                <img className="contact-footer-icon" src = {emailIcon} alt = "E-mail"/>

                info@masterfarm.rs
            </span>

            <span>
                <img className="contact-footer-icon" src = {phoneIcon} alt = "Broj:"/>

                +381 63 7149 708
            </span>

            <span>
                <img className="contact-footer-icon" src = {addressIcon} alt = "Adresa"/>

                Beograd, Riste Odavića 20
            </span>
        </div>

        <div className="links-div">
            <h2 className="footer-info-title"> Linkovi </h2>

            <Link to = ""> Proizvodi </Link>
            <Link to = "/zastupnistva"> Zastupništva </Link>
            <Link to = "/o-nama"> O nama </Link>

        </div>

        <a href="https://www.youtube.com/@masterfarmbeograd9522" className = "youtube-link" target = "blank">
            <div className="youtube-div">
                <img className = "youtube-logo" src = { youtubeIcon } alt = "youtube-img" />

                <p>Masterfarm Beograd</p>
            </div> 
        </a>

    </div>

    <div className = "copyright-div">
        <p className="copyright">© 2023 MASTERFARM D.O.O</p>

        <HashLink smooth to = "/#top">
            <img className = "footer-logo" src = { logo } alt = "logo-img" />
        </HashLink>
    </div> 

  </div>
  )
}

export default Footer