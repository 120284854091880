import React from 'react'
import AboutUsInfo from '../../components/AboutUsInfo/AboutUsInfo'

import './AboutUs.scss';

const AboutUs = () => {
  return (

    <div className = "aboutUs-root">

        <AboutUsInfo />

    </div>
  )
}

export default AboutUs