import React from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';
import logo from '../../assests/logo.svg'

import './header.scss';

const Header = () => {
  return (
    <div className = "header-root" id = "top">

      <div className = "logo-div">

        <Link to = "/">
          <img src = { logo } alt = "logo-img" />
          <h1> MASTERFARM D.O.O </h1>
        </Link>

      </div>

      <div className = "navbar">
          <Link to = ""> Početna </Link>
          <Link to = "/proizvodi"> Proizvodi </Link>
          <Link to = "/zastupnistva"> Zastupništva </Link>
          <Link to = "/onama"> O nama </Link>
          <HashLink smooth to = "/onama#kontakt"> Kontakt </HashLink>
      </div> 

    </div>
  )
}

export default Header