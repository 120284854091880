import React from 'react'
import polanes from '../../assests/polanes.png'


import './RepresentationsInfo.scss'

const RepresentationsInfo = () => {
  return (

    <div className = "representations-info-root">

      <div className = "container">

        <h2 className = "representations-info-title"> Zastupništva </h2>

        <p>
            Uvoznici smo i zastupnici proizvoda sledećih proizvođača: <br /><br />

            * Ugovor o zastupanju - Polones www.polanes.com.pl <br /><br />

            Polones je poljski proizvođač opreme za mašinsku mužu krava, ovaca i koza.
            Proizvodnja mleka je individualan i složen proces. POLANES rešenja i uređaji pomažu hiljadama farmera širom sveta u svom svakodnevnom radu više od 20 godina. Imajući u vidu potrebe i mogućnosti naših kupaca, mi smo tu da im pružimo efikasna i najsavremenija rešenja koja poboljšavaju efikasnost proizvodnje mleka, a takođe poboljšavaju i dobrobit životinja.
        </p>

        <img src= { polanes } alt="img" />

      </div>

    </div>
  )
}

export default RepresentationsInfo