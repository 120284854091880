import React from 'react'
import RepresentationsInfo from '../../components/RepresentationsInfo/RepresentationsInfo';

import './Representations.scss';

const Representations = () => {
  return (

    <div className = "representations-root">

        <RepresentationsInfo />

    </div>
  )
}

export default Representations