import React from 'react'
import ProductsLinks from '../../components/ProductsLinks/ProductsLinks'
import ProductsContent from '../../components/ProductsContent/ProductsContent'

import './Products.scss';

const Products = () => {
  return (
    <div className = "products-root">

        <ProductsLinks />
        <ProductsContent />
        
    </div>
  )
}

export default Products