import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Footer, Header } from './components';
import { AboutUs, Home } from './pages';
import { Representations, Products } from './pages';


import './App.css';

function App() {
  return (
    <Router>

      <div className = 'root'>

        <Header />

        <Routes>

          <Route path = '/' element = {<Home />} />
          <Route path = '/proizvodi' element = {<Products />} />
          <Route path = '/zastupnistva' element = {<Representations />} />
          <Route path = '/onama' element = {<AboutUs />} />

        </Routes>

        <Footer />

      </div>

    </Router>
  );
}

export default App;
