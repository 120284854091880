import React from 'react'

import "./Product.scss"

const Product = ({title, image}) => {
  return (
    <div className = "product-root">

        <img className = "product-image" src = {image} alt = "" />

        <h2 className = "product-title"> { title } </h2>

    </div>
  )
}

export default Product
