import React from 'react'
import ActivitiesInfo from '../../components/ActivitiesInfo/ActivitiesInfo'
import BasicInfo from '../../components/BasicInfo/BasicInfo'
import ProductList from '../../components/ProductList/ProductList';
import Representation from '../../components/Representation/Representation';

import './Home.scss';

const Home = () => {
  return (
    
    <div className = "home-root">

        <BasicInfo />

        <ActivitiesInfo />

        <Representation />

        <ProductList />

    </div>
  )
}

export default Home 