import React from 'react'
import Product from '../Product/Product'
import { HashLink } from 'react-router-hash-link'

import panoramaClasic from '../../assests/panoramaClasic.png'
import panoramaEffect from '../../assests/panoramaEffect.png'
import panoramaHandrail2 from '../../assests/panoramaHandrail2.png'
import cowMilkingSystem from '../../assests/cowMilkingSystem.png'
import sheepAndGoatMilking from '../../assests/sheepAndGoatMilking.png'
import mobilGenerator from '../../assests/mobilGenerator.png'
import ventilation from '../../assests/ventilation.png'
import platformScale from '../../assests/platformScale.png'
import feedingSystem from '../../assests/feedingSystem1.jpg'
import washing from '../../assests/washing1.jpg'
import cowBrush from '../../assests/cowBrush.jpg'

import "./ProductList.scss"

const ProductList = () => {
  return (
    <div className = "product-list-root">

        <h2 className = "products-list-title"> Proizvodi </h2>

        <div className = "product-list">

          <HashLink smooth to = "/proizvodi#panoramaClasic">
            <div>
              <Product className = "product1" title = "PANORAMA CLASIC tip riblje kosti" image = {panoramaClasic} />
            </div>
          </HashLink>

          <HashLink smooth to = "/proizvodi#panoramaSaRukohvatom">
            <div>
              <Product className = "product2" title = "PANORAMA sa rukohvatom" image = {panoramaHandrail2} />
            </div>
          </HashLink>

          <HashLink smooth to = "/proizvodi#panoramaEffect">
            <div>
              <Product className = "product3" title = 'PANORAMA EFFECT tip "strana po strana izmuzišta"' image = {panoramaEffect} />
            </div>
          </HashLink>

          <HashLink smooth to = "/proizvodi#muzaKrava">
            <div>
              <Product className = "product4" title = "Muža krava na veznom sistemu" image = {cowMilkingSystem} />
            </div>
          </HashLink>

          <HashLink smooth to = "/proizvodi#muzaOvacaIKoza">
            <div>
              <Product className = "product5" title = "Muža ovaca i koza" image = {sheepAndGoatMilking} />
            </div>
          </HashLink>

          <HashLink smooth to = "/proizvodi#mobilniAgregat">
            <div>
              <Product className = "product6" title = "Mobilni agregat za mužu krava, ovaca i koza" image = {mobilGenerator} />
            </div>
          </HashLink>

          <HashLink smooth to = "/proizvodi#ventilacija">
            <div>
              <Product className = "product7" title = "Ventilacija" image = {ventilation} />
            </div>
          </HashLink>
            
          <HashLink smooth to = "/proizvodi#platformaSaVagom">
            <div>
              <Product className = "product8" title = "Platforma sa vagom" image = {platformScale} />
            </div>
          </HashLink> 

          <HashLink smooth to = "/proizvodi#sistemZaIshranu">
            <div>
              <Product className = "product9" title = "Sistem za automatsku ishranu" image = {feedingSystem} />
            </div>
          </HashLink>

          <HashLink smooth to = "/proizvodi#pranjeMlekovoda">
            <div>
              <Product className = "product10" title = "Mašine za pranje mlekovoda" image = {washing} />
            </div>
          </HashLink>

          <HashLink smooth to = "/proizvodi#cetkaZaKrave">
            <div>
              <Product className = "product11" title = "Četka za krave" image = {cowBrush} />
            </div>
          </HashLink>

        </div>

    </div>
  )
}

export default ProductList