import React from 'react'
import stickmenTeam from '../../assests/stickmenTeam.png'

import phoneIcon from '../../assests/phoneIcon.svg'
import emailIcon from '../../assests/emailIcon.svg'
import addressIcon from '../../assests/address-location-icon.svg'

import './AboutUsInfo.scss'

const AboutUsInfo = () => {
  return (

    <div className="about-us-info-root">

      <div className="about-us-info-container">

        <h2 className="about-us-info-title"> O nama </h2>

        <img src={stickmenTeam} alt="img" />

        <p>
          Master Farm d.o.o. je porodična firma sa sedištem u Beogradu, Riste Odavića 20. <br /><br />

          Vlasnik i osnivač firme, 2017.god. započeo je samostalno s radom, skupivši tim saradnika sa preko 35 godina iskustva rada sa opremom za mužu. U saradnji sa proizvođačima proizvoda koje nudimo krajnjim korisnicima, uvek koristeći lično iskustvo nastojimo da se prilagodimo željama i potrebama kupaca kako bi ostvarili njihovo zadovoljstvo, jer to je ono što nam je osnovni cilj. <br /><br />

          Naš asortiman čine proizvodi za profesionalno korišćenje u stočarstvu. Nudimo kompletna rešenja opremanja stočarskih farmi od muže do izđubravanja. <br /><br />

          Nadamo se da će te uz naše stalne kupce i Vi u skorijoj budućnosti postati jedan od njih, zato nemojte čekati, obratite nam se telefonom ili mailom!
        </p>

      </div>

      <div className="contact-card" id = "kontakt" >

        <h2 className="contact-card-title"> Kontaktirajte nas</h2>

        <div className="contact-card-content">

          <div className="contact-info-item">

            <img className="icons-img" src={addressIcon} alt="adresa" />

            <h3 className="contact-info-item-title">Adresa</h3>

            <p className="contact-info-item-content">Beograd, Riste Odavića 20</p>

          </div>

          <div className="contact-info-item">

            <img className="icons-img" src={emailIcon} alt="mail" />

            <h3 className="contact-info-item-title">e-mail</h3>

            <p className="contact-info-item-content">info@masterfarm.rs</p>

          </div>

          <div className="contact-info-item">

            <img className="icons-img" src={phoneIcon} alt="telefon" />

            <h3 className="contact-info-item-title">Telefon</h3>

            <p className="contact-info-item-content">+381 63 7149 708</p>

          </div>

        </div>

      </div>

    </div>
  )
}

export default AboutUsInfo