import React from 'react'

import equipment from '../../assests/equipment.png'
import workersImage from '../../assests/workersImage.png'

import './ActivitiesInfo.scss'

const ActivitiesInfo = () => {

  return (
    <div className = "activities-info-root">

      <div className = "content-container">

      <h2 className = "activities-info-title"> Delatnosti </h2>

        <img className = "content-item1" src = { equipment } alt = "img" />

        <p className = "content-item2">
          Prodaja opreme za mužu koza, krava i ovaca.
        </p>

        <p className = "content-item3">
          Servis u garantnom i vangarantnom roku opreme za mužu krava, ovaca i koza. <br /> <br /> <br /> 

          Prodaja, montaža i održavanje opreme za izđubravanje štala.
        </p>

        <img className = "content-item4" src = { workersImage } alt = "img" />

      </div>

    </div>
  )

}

export default ActivitiesInfo