import React from 'react'
import ProductsContentItem from '../ProductsContentItem/ProductsContentItem'

import cowBrush from '../../assests/cowBrush.jpg'
import washing from '../../assests/washing1.jpg'
import washing2 from '../../assests/washing2.jpg'
import washing3 from '../../assests/washing3.jpg'
import feedingSystem from '../../assests/feedingSystem1.jpg'
import feedingSystem2 from '../../assests/feedingSystem2.JPG'
import feedingSystem3 from '../../assests/feedingSystem3.png'
import feedingStystemPDF from '../../assests/feedingSystem.pdf'
import cowMilkingSystemPDF from '../../assests/cowMilkingSystem.pdf'
import panoramaClasic from '../../assests/panoramaClasic.png'
import panoramaClasic2 from '../../assests/panoramaClasic2.png'
import panoramaClasic3 from '../../assests/panoramaClasic3.png'
import panoramaClasic4 from '../../assests/panoramaClassic4.jpg'
import panoramaClasic5 from '../../assests/panoramaClassic5.jpg'
import panoramaClasic6 from '../../assests/panoramaClassic6.jpg'
import panoramaClasic7 from '../../assests/panoramaClassic7.jpg'
import panoramaClasic8 from '../../assests/panoramaClassic8.jpg'
import panoramaClasic9 from '../../assests/panoramaClassic9.jpg'
import panoramaClasic10 from '../../assests/panoramaClassic10.jpg'
import panoramaHandrail from '../../assests/panoramaHandrail.png'
import panoramaHandrail2 from '../../assests/panoramaHandrail2.png'
import panoramaHandrail3 from '../../assests/panoramaHandrail3.png'
import panoramaHandrail4 from '../../assests/panoramaHandrail4.jpg'
import panoramaHandrail5 from '../../assests/panoramaHandrail5.jpg'
import panoramaHandrail6 from '../../assests/panoramaHandrail6.jpg'
import panoramaEffect from '../../assests/panoramaEffect.png'
import panoramaEffect2 from '../../assests/panoramaEffect2.png'
import panoramaEffect3 from '../../assests/panoramaEffect3.png'
import panoramaEffect4 from '../../assests/panoramaEffect4.jpg'
import panoramaEffect5 from '../../assests/panoramaEffect5.jpg'
import panoramaEffect6 from '../../assests/panoramaEffect6.jpg'
import panoramaEffect7 from '../../assests/panoramaEffect7.jpg'
import panoramaEffect8 from '../../assests/panoramaEffect8.JPG'
import panoramaEffect9 from '../../assests/panoramaEffect9.JPG'
import panoramaEffect10 from '../../assests/panoramaEffect10.jpg'
import cowMilkingSystem from '../../assests/cowMilkingSystem.jpg'
import cowMilkingSystem2 from '../../assests/cowMilkingSystem2.png'
import cowMilkingSystem3 from '../../assests/cowMilkingSystem3.png'
import cowMilkingSystem4 from '../../assests/cowMilkingSystem4.jpg'
import sheepAndGoatMilking from '../../assests/sheepAndGoatMilking.png'
import sheepAndGoatMilking2 from '../../assests/sheepAndGoatMilking2.png'
import sheepAndGoatMilking3 from '../../assests/sheepAndGoatMilking3.png'
import mobilGenerator from '../../assests/mobilGenerator.png'
import mobilGenerator2 from '../../assests/mobilGenerator2.png'
import ventilation from '../../assests/ventilation.png'
import ventilation2 from '../../assests/ventilation2.png'
import platformScale from '../../assests/platformScale.png'
import platformScale2 from '../../assests/platformScale2.png'

import './ProductsContent.scss';

const ProductsContent = () => {

  return (
    <div>
      <div id = "panoramaClasic">
        <ProductsContentItem 
          title = "PANORAMA CLASSIC tip 'riblje kosti'"

          paragraph = { <p> Ova vrsta izmuzišta je najpopularnija od svih rešenja koja se koriste u našoj zemlji. Ovo nije bez razloga. Ovo rešenje je popularno zbog jednostavnosti sistema, niske cene i male površine zauzete po jednoj kravi. Ovim rešenjem, muzač ne samo što brzo pristupa kravljim vimenima, već ima i udoban i siguran radni položaj. Postavljanje krava u blizini jedne do druge štedi prostor i smanjuje rastojanje i vreme stavljanja uređaja za mužu. Grupisanje krava u stado po sličnom vremenu muže je ključni element u sistemu. Ovakvo rešenje smanjuje trajanje muže i smanjuje stres krava vezanih za proces muže. <br /><br /> Ključne karakteristike i prednosti sistema riblje kosti: <br /><br /> 1. Povoljna cena i korisnost. <br /> 2. Krave brzo zauzimaju svoja mesta. <br /> 3. Bezbedan rad za muzače. <br /> 4. Podešavanje ugla pristupa pozicioniranju krave. <br /> 5. Mnoge mogućnosti prilagođavanja u postojećim objektima. <br /> 6. Opciona dodatna oprema, koja odgovara potrebama i zahtevima. </p> }
          
          images = {[ panoramaClasic2, panoramaClasic4 , panoramaClasic6, panoramaClasic7, panoramaClasic8, panoramaClasic9 ]} />
      </div>

      <div id = "panoramaSaRukohvatom">
        <ProductsContentItem 
          title = "PANORAMA sa rukohvatom" 
          
          paragraph = { <p> Sistem sa pomičnim rukohvatom služi za mužu dve strane istovremeno, što značajno poboljšava efikasnost investicije i kasniju upotrebu sistema. Glavna prednost ove metode muže je znatno kraće vreme za mužu, za čak 25% u poređenju sa sistemom za mužu riblje kosti. Muzač istovremeno upravlja sa dve strane izmuzišta. Dok se krave muzu sa jedne strane, krave sa druge strane podležu higijenskim tretmanima i zamenama. <br /><br /> Ključne karakteristike i prednosti muže sa sistemom Sving Over: <br /><br />

            1. Manji broj jedinica za mužu - jedan uređaj se koristi za dve strane izmuzišta. <br />
            
            2. Kraća dužina cevovoda transporta mleka. <br />
            
            3. Bolja upotreba ljudskog rada u procesu muže.
            </p> } 
          
          images = {[ panoramaHandrail2, panoramaHandrail3, panoramaHandrail4, panoramaHandrail5, panoramaHandrail6 ]} />
        </div>

        <div id = "panoramaEffect">
          <ProductsContentItem 
            title = "PANORAMA EFFECT tip 'strana po strana izmuzišta'" 
            
            paragraph = { <p> Krave zauzimaju svoja mesta u prostoru za mužu, stoje pod uglom od 90 stepeni do kanala za mužu. Krajnji cilj ovog rešenja je da obezbedi visoke performanse zahvaljujući brzom procesu pozicioniranja krava za mužu i visokim kapacitetima sistema. Grupisanje krava u stado u sličnom periodu muže je ključni element u sistemu. Ovakvo rešenje smanjuje trajanje muže i smanjuje stres krava vezanog za proces muže. <br /><br /> Ključne karakteristike i prednosti sistema: <br /><br />

              1. Brza operacija. <br />
              
              2. Visoke performanse. <br />
              </p> } 
            
            images = {[ panoramaEffect, panoramaEffect2, panoramaEffect3, panoramaEffect4, panoramaEffect5, panoramaEffect6, panoramaEffect7, panoramaEffect8, panoramaEffect9, panoramaEffect10 ]} />
        </div>

        <div id = "muzaKrava">
          <ProductsContentItem 
            title = "Muža krava na veznom sistemu" 
            
            paragraph = { <p>  Sistem za mužu je ključni radni alat za svakog stočara. Oprema za mužu radi približno 4-6 sati dnevno, 365 dana godišnje, što daje ukupno 1500-2000 sati godišnje. Za uzgajanje krava potrebna je oprema za mužu koja je jednostavna za rukovanje, funkcionalna i, pre svega pouzdana. <br /><br /> Uz dugogodišnje iskustvo i stručnjake sa raznovrsnim profesionalnim znanjem, spremni smo da dizajniramo sistem koji odgovara vašim zahtevima i potrebama. Nudimo našu pomoć od trenutka kada nas kontaktirate dok se projekat ne završi. POLANES-ova muža krava na veznom sistemu je sveobuhvatni sistem za mužu krava koji naši stručnjaci mogu izraditi kako bi zadovoljili vaše potrebe. <br /><br/> <a href = {cowMilkingSystemPDF} target = "blank">Pogledajte katalog</a> </p> } 
            
            images = {[ cowMilkingSystem ]} />
        </div>

        <div id = "muzaOvacaIKoza">
          <ProductsContentItem 
            title = "Muža ovaca i koza" 
            
            paragraph = { <p> Sposobnost praćenja životinja i lakog pristupa vimenima obezbeđen je sistemom za mužu ovaca i koza. Izmuzišta su opremljena sa koritom za hranjenje i blokadom i omogućavaju laganu, ergonomsku i efikasnu mužu. Izmuzišta su opremljena najsavremenijim sistemom za merenje zapremine mleka i sistemom za automatsko zatvaranje vakuuma kako bi se izbegla neželjena prekomerna muža. </p> } 
            
            images = {[ sheepAndGoatMilking, sheepAndGoatMilking2, sheepAndGoatMilking3 ]} />
        </div>

        <div id = "mobilniAgregat">
          <ProductsContentItem 
            title = "Mobilni agregat za mužu krava, ovaca i koza" 
            
            paragraph = { <p> Prva faza procesa automatizacije muže, održavanja higijene i visokog kvaliteta mleka. <br /><br />
            Upotreba mobilnih agregata za mužu na farmama značajno smanjuje vreme muže i količinu svakodnevnog ručnog rada sa životinjama. <br /><br /> Mobilni agregat za mužu poboljšava mužu i smanjuje vreme prevoza mleka u laktofriz. Mobilni agregat za mužu je jednostavan za upotrebu i može se brzo premestiti na drugu lokaciju za mužu.
            Mobilni agregat za mužu krava, koza ili ovaca su uređaji namenjeni malim i srednjim stadima. </p> } 
            
            images = {[ mobilGenerator, mobilGenerator2 ]} />
        </div>

        <div id = "ventilacija">
          <ProductsContentItem 
            title = "Ventilacija" 
            
            paragraph = { <p> Jedan od osnovnih uslova za obezbeđivanje dobrobiti životinja i visoku proizvodnju mleka je održavanje odgovarajuće ventilacije. <br /><br /> Mikroklima koja preovlađuje u štalama ima veliki uticaj na zdravlje i stanje životinja, kao i na prinos mleka stoke. </p> } 
            
            images = {[ ventilation, ventilation2 ]} />
        </div>

        <div id = "platformaSaVagom">
          <ProductsContentItem 
            title = "Platforma sa vagom" 
            
            paragraph = { <p> Efekti vidljivi na težini krave.Precizno i redovno merenje krava omogućava analizu potreba krava u odnosu na dobit mleka. Predstavljamo prvo rešenje koje pokriva težinu krave i sarađuje sa sistemom za hranu i sistemom za upravljanje stada. </p> } 
            
            images = {[ platformScale, platformScale2 ]} />
        </div>

        <div id = "sistemZaIshranu">
          <ProductsContentItem 
            title = "Sistem za automatsku ishranu" 
            
            paragraph = { <p> Pročitajte više o proizvodu u katalogu <a href = {feedingStystemPDF} target = "blank">ovde</a>. </p> } 
            
            images = {[ feedingSystem, feedingSystem2, feedingSystem3 ]} />
        </div>

        <div id = "pranjeMlekovoda">
          <ProductsContentItem 
            title = "Mašine za pranje mlekovoda" 
                
            images = {[ washing, washing2, washing3 ]} />
        </div>

        <div id = "cetkaZaKrave">
          <ProductsContentItem 
            title = "Četka za krave" 

            images = {[ cowBrush ]} />
        </div>

    </div>
  )
}

export default ProductsContent