import React from 'react'

import './ProductsContentItem.scss';

const ProductsContentItem = ({ title, paragraph = <p />, images = [] }) => {

  function addImages() {
    if (images.length === 0 ) return

    return  <div> { 
              images.map(
                image => <img className = "products-content-item-image" src= {image}  alt="" />
                )  } 
            </div>
  }

  return (
      
    <div className = "products-content-item-root">

        <h2 className = "products-content-item-title"> {title} </h2>

        <div className = "products-content-item-paragraph"> { paragraph } </div>

        <div className = "image-div" > { addImages() } </div>
        
    </div>
  )
}

export default ProductsContentItem