import React from 'react'
import { HashLink } from 'react-router-hash-link';

import './ProductsLinks.scss'

const ProductsLinks = () => {
  return (

    <div className = "products-links-root">
        
        <h2 className = "products-links-title">  Naši proizvodi </h2>

        <div className = "products-links-hash">

            <div>
            <HashLink smooth to = "/proizvodi#panoramaClasic"> PANORAMA CLASIC tip riblje kosti </HashLink>
            <span className = "dot"></span>
            <HashLink smooth to = "/proizvodi#panoramaSaRukohvatom"> PANORAMA sa rukohvatom </HashLink>
            <span className = "dot"></span>
            <HashLink smooth to = "/proizvodi#panoramaEffect"> PANORAMA EFFECT tip "strana po strana izmuzišta" </HashLink>
            <span className = "dot"></span>
            <HashLink smooth to = "/proizvodi#sistemZaIshranu"> Sistem za automatsku ishranu </HashLink>
            <span className = "invisible-dot"></span>
            </div>


            <div> 
            <HashLink smooth to = "/proizvodi#muzaKrava"> Muža krava na veznom sistemu </HashLink>
            <span className = "dot"></span>
            <HashLink smooth to = "/proizvodi#muzaOvacaIKoza"> Muža ovaca i koza </HashLink>
            <span className = "dot"></span>
            <HashLink smooth to = "/proizvodi#platformaSaVagom"> Platforma sa vagom </HashLink>
            <span className = "dot"></span>
            <HashLink smooth to = "/proizvodi#cetkaZaKrave"> Četka za krave </HashLink>
            <span className = "invisible-dot"></span>
            </div>

            <div>
            <HashLink smooth to = "/proizvodi#mobilniAgregat"> Mobilni agregat za mužu krava, ovaca i koza </HashLink>
            <span className = "dot"></span>
            <HashLink smooth to = "/proizvodi#ventilacija"> Ventilacija </HashLink>
            <span className = "dot"></span>
            <HashLink smooth to = "/proizvodi#pranjeMlekovoda"> Mašine za pranje mlekovoda </HashLink>
            <span className = "invisible-dot"></span>
            </div>

        </div>

    </div>

  )
}

export default ProductsLinks