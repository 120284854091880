import React from 'react'
import { HashLink } from 'react-router-hash-link';

import './BasicInfo.scss'

const BasicInfo = () => {    
    return (
        <div className = "basic-info-root">

            <h2 className = 'basic-info-title'> Prodaja, servis i montaža opreme za mužu koza, krava i ovaca </h2>
            
            <p>MasterFarm D.O.O. je porodična firma sa sedištem u Beogradu, Riste Odavića 20. <br /> <br />
                Naš asortiman čine proizvodi za profesionalno korišćenje u stočarstvu.
                Nudimo kompletna rešenja opremanja stočarskih farmi od muže do izđubravanja. </p>

            <HashLink smooth to = "/o-nama#top" >
                <button type="button" className="button-secondary button-see-more">Vidi više</button>
            </HashLink>
            
        </div>
    )
}

export default BasicInfo